<template>
  <div>
    <div class="wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 30px;">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/ourbrands' }">OurBrands</el-breadcrumb-item>
        <el-breadcrumb-item>Gifts for Paws</el-breadcrumb-item>
      </el-breadcrumb>
      <img src="../assets/imgs/uploads/brandsgp.jpg" alt="" class="brand">
      <p class="best">Best Sellers</p>
      <div style="display: flex;justify-content: center;font-weight: bold;">Waiting for new items to hit shelves...</div>
      <!-- <div style="display: flex;justify-content: space-between;margin-bottom: 60px;">
        <div class="goods" v-for="item in goods" :key="item.id">
          <div class="hot">
            Hot
          </div>
          <router-link :to="{ path: '/details', query: { spuId: item.id } }">
            <div style="margin-top: 23px;display: flex;justify-content: center;">
              <img v-lazy="item.spuImage" alt="" style="width: 210px;height: 210px;">
            </div>
            <div style="margin-top: 20px;display: flex;justify-content: center;font-weight: bold;font-size: 14px;">
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div> -->
      <p class="best">Shop by Range</p>
      <router-link to="/goods">
        <div style="display: flex;justify-content: space-evenly;cursor: pointer;">
          <div v-for="item in cat" :key="item.id" class="range">
            <img :src="item.imgPath" style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;" alt="">
            <div class="tip">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: space-evenly;cursor: pointer;margin-top: 46px;">
          <div v-for="item in dog" :key="item.id" class="range">
            <img :src="item.imgPath" style="width: 210px;height: 210px;border-radius: 20px;transition: all 0.6s;" alt="">
            <div class="tip">
              {{ item.name }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="foot">
      <div class="wrapper" style="overflow: hidden;">
        <div style="margin: 76px 0 44px;display: flex;justify-content: center;">
          <img src="../assets/imgs/uploads/gplogo.png" style="width: 259px;height: 53px;" alt="">
        </div>
        <div style="color: #FFF;text-align: center;">
          Gifts for Paws is dedicated to creating a unique and joyful world for you and your beloved pets. Each toy is
          carefully designed to not only meet the play needs of pets but also to showcase their distinctive personalities
          while playing. Pets are our family and friends, so we not only focus on appearance but also prioritize safety
          and comfort. We use high-quality materials to ensure durability and safety. Gifts for Paws helps you establish a
          deeper emotional connection with your pets. Whether it's playing, interacting, or simply being together, we aim
          to enhance the bond between you and your beloved pets, allowing you both to enjoy each other's company to the
          fullest.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goods: [],
      cat: [
        { id: 1, imgPath: require('../assets/imgs/uploads/gigwicat1.jpg'), name: 'Cat' },
        { id: 2, imgPath: require('../assets/imgs/uploads/gigwicat2.jpg'), name: 'Cat Gift Box' },
        { id: 3, imgPath: require('../assets/imgs/uploads/gigwicat3.jpg'), name: 'Scratcher' },
        { id: 4, imgPath: require('../assets/imgs/uploads/gigwicat4.jpg'), name: 'Teaser Stick' },
        { id: 5, imgPath: require('../assets/imgs/uploads/gigwicat5.jpg'), name: 'Cat Tree' },
      ],
      dog: [
        { id: 1, imgPath: require('../assets/imgs/uploads/gigwidog1.jpg'), name: 'Dog' },
        { id: 2, imgPath: require('../assets/imgs/uploads/gigwidog2.jpg'), name: 'Dog Gift Box' },
        { id: 3, imgPath: require('../assets/imgs/uploads/gigwidog3.jpg'), name: 'Plush Toy' },
        { id: 4, imgPath: require('../assets/imgs/uploads/gigwidog4.jpg'), name: 'Chew Toy' },
        { id: 5, imgPath: require('../assets/imgs/uploads/gigwidog5.jpg'), name: 'Fetch&Retrive' },
      ],
    }
  },
  mounted() {
    this.getGigwiBestSells()
  },
  methods: {
    getGigwiBestSells() {
      this.$axios
        .post("/api/mall/home/getSKIPDAWGBestSells")
        .then(res => {
          this.goods = res.data.data;
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },
}
</script>
<style scoped>
.wrapper {
  width: 1280px;
  margin: 0 auto;
}

.brand {
  width: 100%;
  height: 322px;
  margin-top: 30px;
}

.best {
  margin: 60px auto;
  font-family: 'Jomolhari';
  font-size: 36px;
  display: flex;
  justify-content: center;
}

.goods {
  width: 242px;
  height: 305px;
  border: 1px solid #D9D9D9;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.hot {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 33px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 20px 0 20px 0;
  color: #FFF;
  background-color: #FF764B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range {
  width: 210px;
  height: 210px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.tip {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 17px;
  color: #FFF;
}

.range:hover img {
  transform: scale(1.3)
}

.foot {
  margin-top: 140px;
  width: 100%;
  height: 415px;
  background-color: #CE9F6A;
}</style>